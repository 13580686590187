// Analytics configuration
const isProd = window.location.hostname === 'bigbrainsports.io';
const SESSION_STORAGE_KEY = 'bbs_alumni_game_session';

function logAnalytics(type: string, data: Record<string, any>): void {
  if (!isProd) {
    console.log(`[BBS Analytics - ${type}]`, data);
  }
}

// Track game start
export function trackAlumniGameStart(): void {
  const sessionId = `bbs_${Date.now()}`;
  sessionStorage.setItem(SESSION_STORAGE_KEY, sessionId);
  
  logAnalytics('Game Start', { timestamp: Date.now() });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'bbs_alumni_game_start', {
      event_category: 'BBS Alumni Game',
      event_label: sessionId
    });
  }
}

// Track round start
export function trackRoundStart(roundNumber: number, playerId: number): void {
  logAnalytics('Round Start', { roundNumber, playerId });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'bbs_alumni_round_start', {
      event_category: 'BBS Alumni Game',
      event_label: `Round ${roundNumber}`,
      value: playerId
    });
  }
}

// Track answer submission
export function trackAnswerSubmit(
  roundNumber: number,
  playerId: number,
  answer: string,
  isCorrect: boolean,
  timeSpent: number,
  streak: number,
  score: number
): void {
  logAnalytics('Answer Submit', {
    roundNumber,
    playerId,
    answer,
    isCorrect,
    timeSpent,
    streak,
    score
  });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'bbs_alumni_answer', {
      event_category: 'BBS Alumni Game',
      event_label: `Player ${playerId} - ${isCorrect ? 'Correct' : 'Incorrect'}`,
      value: timeSpent,
      metric1: streak,
      metric2: score
    });
  }
}

// Track game completion
export function trackAlumniGameComplete(
  finalScore: number,
  maxStreak: number,
  correctAnswers: number,
  totalTime: number,
  totalRounds: number
): void {
  const sessionId = sessionStorage.getItem(SESSION_STORAGE_KEY);
  
  logAnalytics('Game Complete', {
    finalScore,
    maxStreak,
    correctAnswers,
    totalTime,
    totalRounds,
    sessionId
  });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'bbs_alumni_game_complete', {
      event_category: 'BBS Alumni Game',
      event_label: `Score: ${finalScore}`,
      value: finalScore,
      metric1: maxStreak,
      metric2: correctAnswers,
      metric3: totalTime
    });

    // Track perfect game achievement
    if (correctAnswers === totalRounds) {
      window.gtag('event', 'bbs_alumni_achievement', {
        event_category: 'BBS Alumni Game',
        event_label: 'Perfect Game',
        value: finalScore
      });
    }
  }
}

// Track card flip
export function trackCardFlip(roundNumber: number, viewTime: number): void {
  logAnalytics('Card Flip', { roundNumber, viewTime });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'bbs_alumni_card_flip', {
      event_category: 'BBS Alumni Game',
      event_label: `Round ${roundNumber}`,
      value: viewTime
    });
  }
}

// Track share actions
export function trackShare(platform: string): void {
  logAnalytics('Share', { platform });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'bbs_alumni_share', {
      event_category: 'BBS Alumni Game',
      event_label: platform
    });
  }
}

// Track streak achievements
export function trackStreak(streak: number): void {
  logAnalytics('Streak', { streak });

  if (typeof window.gtag !== 'undefined' && isProd) {
    window.gtag('event', 'bbs_alumni_streak', {
      event_category: 'BBS Alumni Game',
      event_label: `${streak}x Streak`,
      value: streak
    });
  }
}