export const D1_SCHOOLS = [
  "Air Force",
  "Akron",
  "Alabama",
  "Alabama A&M",
  "Alabama State",
  "Albany",
  "Alcorn State",
  "American",
  "Appalachian State",
  "Arizona",
  "Arizona State",
  "Arkansas",
  "Arkansas State",
  "Arkansas-Pine Bluff",
  "Auburn",
  "Austin Peay",
  "Ball State",
  "Baylor",
  "Bellarmine",
  "Belmont",
  "Bethune-Cookman",
  "Binghamton",
  "Boise State",
  "Boston College",
  "Boston University",
  "Bowling Green",
  "Bradley",
  "Brown",
  "Bryant",
  "Bucknell",
  "Buffalo",
  "Butler",
  "BYU",
  "Cal Baptist",
  "Cal Poly",
  "Cal State Bakersfield",
  "Cal State Fullerton",
  "Cal State Northridge",
  "California",
  "Campbell",
  "Canisius",
  "Central Arkansas",
  "Central Connecticut",
  "Central Michigan",
  "Charleston",
  "Charleston Southern",
  "Charlotte",
  "Chattanooga",
  "Chicago State",
  "Cincinnati",
  "Clemson",
  "Cleveland State",
  "Coastal Carolina",
  "Colgate",
  "Colorado",
  "Colorado State",
  "Columbia",
  "Connecticut",
  "Coppin State",
  "Cornell",
  "Creighton",
  "Dartmouth",
  "Davidson",
  "Dayton",
  "Delaware",
  "Delaware State",
  "Denver",
  "DePaul",
  "Detroit Mercy",
  "Drake",
  "Drexel",
  "Duke",
  "Duquesne",
  "East Carolina",
  "East Tennessee State",
  "Eastern Illinois",
  "Eastern Kentucky",
  "Eastern Michigan",
  "Eastern Washington",
  "Elon",
  "Evansville",
  "Fairfield",
  "Fairleigh Dickinson",
  "Florida",
  "Florida A&M",
  "Florida Atlantic",
  "Florida Gulf Coast",
  "Florida International",
  "Florida State",
  "Fordham",
  "Fresno State",
  "Furman",
  "Gardner-Webb",
  "George Mason",
  "George Washington",
  "Georgetown",
  "Georgia",
  "Georgia Southern",
  "Georgia State",
  "Georgia Tech",
  "Gonzaga",
  "Grambling",
  "Grand Canyon",
  "Green Bay",
  "Hampton",
  "Hartford",
  "Harvard",
  "Hawaii",
  "High Point",
  "Hofstra",
  "Holy Cross",
  "Houston",
  "Houston Christian",
  "Howard",
  "Idaho",
  "Idaho State",
  "Illinois",
  "Illinois Chicago",
  "Illinois State",
  "Incarnate Word",
  "Indiana",
  "Indiana State",
  "Iona",
  "Iowa",
  "Iowa State",
  "IUPUI",
  "Jackson State",
  "Jacksonville",
  "Jacksonville State",
  "James Madison",
  "Kansas",
  "Kansas City",
  "Kansas State",
  "Kennesaw State",
  "Kent State",
  "Kentucky",
  "La Salle",
  "Lafayette",
  "Lamar",
  "Lehigh",
  "Liberty",
  "Lindenwood",
  "Lipscomb",
  "Little Rock",
  "LIU",
  "Long Beach State",
  "Longwood",
  "Louisiana",
  "Louisiana Tech",
  "Louisville",
  "Loyola Chicago",
  "Loyola Maryland",
  "Loyola Marymount",
  "LSU",
  "Maine",
  "Manhattan",
  "Marist",
  "Marquette",
  "Marshall",
  "Maryland",
  "Maryland Eastern Shore",
  "Massachusetts",
  "McNeese",
  "Memphis",
  "Mercer",
  "Merrimack",
  "Miami (FL)",
  "Miami (OH)",
  "Michigan",
  "Michigan State",
  "Middle Tennessee",
  "Milwaukee",
  "Minnesota",
  "Mississippi State",
  "Mississippi Valley State",
  "Missouri",
  "Missouri State",
  "Monmouth",
  "Montana",
  "Montana State",
  "Morehead State",
  "Morgan State",
  "Mount St. Mary's",
  "Murray State",
  "Navy",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Mexico",
  "New Mexico State",
  "New Orleans",
  "Niagara",
  "Nicholls",
  "NJIT",
  "Norfolk State",
  "North Alabama",
  "North Carolina",
  "North Carolina A&T",
  "North Carolina Central",
  "North Carolina State",
  "North Dakota",
  "North Dakota State",
  "North Florida",
  "North Texas",
  "Northeastern",
  "Northern Arizona",
  "Northern Colorado",
  "Northern Illinois",
  "Northern Iowa",
  "Northern Kentucky",
  "Northwestern",
  "Northwestern State",
  "Notre Dame",
  "Oakland",
  "Ohio",
  "Ohio State",
  "Oklahoma",
  "Oklahoma State",
  "Old Dominion",
  "Ole Miss",
  "Omaha",
  "Oral Roberts",
  "Oregon",
  "Oregon State",
  "Pacific",
  "Penn",
  "Penn State",
  "Pepperdine",
  "Pittsburgh",
  "Portland",
  "Portland State",
  "Presbyterian",
  "Princeton",
  "Providence",
  "Purdue",
  "Purdue Fort Wayne",
  "Queens",
  "Quinnipiac",
  "Radford",
  "Rhode Island",
  "Rice",
  "Richmond",
  "Rider",
  "Robert Morris",
  "Rutgers",
  "Sacramento State",
  "Sacred Heart",
  "Saint Francis",
  "Saint Joseph's",
  "Saint Louis",
  "Saint Mary's",
  "Saint Peter's",
  "Sam Houston",
  "Samford",
  "San Diego",
  "San Diego State",
  "San Francisco",
  "San Jose State",
  "Santa Clara",
  "Seattle",
  "Seton Hall",
  "Siena",
  "SIU Edwardsville",
  "SMU",
  "South Alabama",
  "South Carolina",
  "South Carolina State",
  "South Dakota",
  "South Dakota State",
  "South Florida",
  "Southeast Missouri State",
  "Southeastern Louisiana",
  "Southern",
  "Southern Illinois",
  "Southern Indiana",
  "Southern Miss",
  "Southern Utah",
  "St. Bonaventure",
  "St. Francis Brooklyn",
  "St. John's",
  "St. Thomas",
  "Stanford",
  "Stephen F. Austin",
  "Stetson",
  "Stonehill",
  "Stony Brook",
  "Syracuse",
  "Tarleton State",
  "TCU",
  "Temple",
  "Tennessee",
  "Tennessee State",
  "Tennessee Tech",
  "Texas",
  "Texas A&M",
  "Texas A&M-Commerce",
  "Texas A&M-Corpus Christi",
  "Texas Southern",
  "Texas State",
  "Texas Tech",
  "The Citadel",
  "Toledo",
  "Towson",
  "Troy",
  "Tulane",
  "Tulsa",
  "UAB",
  "UC Davis",
  "UC Irvine",
  "UC Riverside",
  "UC San Diego",
  "UC Santa Barbara",
  "UCF",
  "UCLA",
  "UIC",
  "UMass Lowell",
  "UMBC",
  "UMES",
  "UNC Asheville",
  "UNC Greensboro",
  "UNC Wilmington",
  "UNLV",
  "USC",
  "USC Upstate",
  "UT Arlington",
  "UT Martin",
  "UT Rio Grande Valley",
  "Utah",
  "Utah State",
  "Utah Tech",
  "Utah Valley",
  "UTEP",
  "UTSA",
  "Valparaiso",
  "Vanderbilt",
  "VCU",
  "Vermont",
  "Villanova",
  "Virginia",
  "Virginia Tech",
  "VMI",
  "Wagner",
  "Wake Forest",
  "Washington",
  "Washington State",
  "Weber State",
  "West Virginia",
  "Western Carolina",
  "Western Illinois",
  "Western Kentucky",
  "Western Michigan",
  "Wichita State",
  "William & Mary",
  "Winthrop",
  "Wisconsin",
  "Wofford",
  "Wright State",
  "Wyoming",
  "Xavier",
  "Yale",
  "Youngstown State"
];