import React from 'react';
import { motion } from 'framer-motion';
import { Share2, Copy, Twitter, Trophy } from 'lucide-react';
import Confetti from 'react-confetti';
import CountUp from 'react-countup';
import { TiktokIcon } from '../../../components/TiktokIcon';
import { useWindowSize } from '../hooks/useWindowSize';
import { generateShareText } from '../utils/sharing';
import { Toast } from './Toast';

interface GameOverScreenProps {
  score: number;
  correctGuesses: number;
  totalPlayers: number;
  streak: number;
  onPlayAgain: () => void;
}

export function GameOverScreen({ score, correctGuesses, totalPlayers, streak, onPlayAgain }: GameOverScreenProps) {
  const [showConfetti, setShowConfetti] = React.useState(true);
  const [copied, setCopied] = React.useState(false);
  const [isSharing, setIsSharing] = React.useState(false);
  const { width, height } = useWindowSize();
  const isPerfectGame = correctGuesses === totalPlayers;

  const shareText = generateShareText(score, streak, isPerfectGame);

  const handleShare = async () => {
    setIsSharing(true);
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'School Pride Challenge',
          text: shareText,
          url: 'https://schoolpride.game'
        });
      } catch (err) {
        console.error('Error sharing:', err);
      }
    }
    setIsSharing(false);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareText);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Error copying:', err);
    }
  };

  const handleTweet = () => {
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`;
    window.open(url, '_blank');
  };

  React.useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4">
      {showConfetti && isPerfectGame && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={200}
          gravity={0.3}
        />
      )}
      
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white rounded-2xl shadow-xl max-w-md w-full overflow-hidden"
      >
        <div className="p-6 text-center">
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", duration: 0.6 }}
            className="mb-4"
          >
            <span className="text-[72px] font-cal font-bold leading-none bg-gradient-to-br from-orange-500 to-orange-600 bg-clip-text text-transparent">
              <CountUp
                end={score}
                duration={2}
                separator=","
              />
            </span>
            {isPerfectGame && (
              <motion.div
                initial={{ scale: 0, rotate: -180 }}
                animate={{ scale: 1, rotate: 0 }}
                transition={{ delay: 0.5, type: "spring" }}
                className="absolute -top-4 -right-4"
              >
                <Trophy className="h-12 w-12 text-yellow-500 drop-shadow-lg" />
              </motion.div>
            )}
          </motion.div>

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="space-y-2 mb-8"
          >
            <p className="text-2xl font-bold text-gray-900">
              {isPerfectGame ? '🏆 Perfect Game!' : 'Game Over!'}
            </p>
            <p className="text-gray-600">
              You got {correctGuesses} out of {totalPlayers} correct
            </p>
            {streak >= 3 && (
              <p className="text-orange-500 font-semibold text-lg">
                🔥 Best Streak: {streak}
              </p>
            )}
          </motion.div>

          <div className="grid grid-cols-2 gap-3 mb-6">
            {navigator.share && (
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleShare}
                className="flex items-center justify-center gap-2 bg-blue-600 text-white p-3 rounded-xl font-medium hover:bg-blue-700 transition-colors col-span-2"
              >
                <Share2 className="h-5 w-5" />
                <span>{isSharing ? 'Sharing...' : 'Share Result'}</span>
              </motion.button>
            )}
            
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleTweet}
              className="flex items-center justify-center gap-2 bg-[#1DA1F2] text-white p-3 rounded-xl font-medium hover:opacity-90 transition-opacity"
            >
              <Twitter className="h-5 w-5" />
              <span>Tweet</span>
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleCopy}
              className="flex items-center justify-center gap-2 bg-gray-100 text-gray-900 p-3 rounded-xl font-medium hover:bg-gray-200 transition-colors relative"
            >
              <Copy className="h-5 w-5" />
              <span>{copied ? 'Copied!' : 'Copy'}</span>
            </motion.button>
          </div>

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onPlayAgain}
            className="w-full py-3 bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-lg font-medium hover:from-blue-700 hover:to-blue-800 transition-colors"
          >
            Play Again
          </motion.button>

          <div className="mt-8 space-y-4">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <a
                href="https://www.tiktok.com/@mobibojoe"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-gray-900 transition-colors inline-flex items-center gap-2"
              >
                Created by @mobibojoe <TiktokIcon className="h-4 w-4" />
              </a>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <a
                href="https://www.mobibojoe.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-700 transition-colors font-medium"
              >
                Subscribe to my newsletter →
              </a>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}