export function generateShareText(score: number, streak: number, isPerfectGame: boolean): string {
  const shareText = `🏀🎓 Alumni Game: ${score} points\n${
    isPerfectGame
      ? 'Perfect Game! 🎯'
      : streak >= 5
      ? `${streak}x Streak! 🔥`
      : 'Test your college hoops knowledge!'
  }\n\nPlay at bigbrainsports.io`;
  
  // Save score to localStorage
  try {
    const highScore = localStorage.getItem('bbs_alumni_game_highScore');
    if (!highScore || Number(highScore) < score) {
      localStorage.setItem('bbs_alumni_game_highScore', score.toString());
    }
    
    // Track total games played
    const gamesPlayed = Number(localStorage.getItem('bbs_alumni_game_gamesPlayed') || '0');
    localStorage.setItem('bbs_alumni_game_gamesPlayed', (gamesPlayed + 1).toString());
    
    // Track perfect games
    if (isPerfectGame) {
      const perfectGames = Number(localStorage.getItem('bbs_alumni_game_perfectGames') || '0');
      localStorage.setItem('bbs_alumni_game_perfectGames', (perfectGames + 1).toString());
    }
  } catch (err) {
    console.error('Error saving game stats:', err);
  }
  
  return shareText;
}