import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';

interface HowToPlayProps {
  isOpen: boolean;
  onClose: () => void;
}

export function HowToPlay({ isOpen, onClose }: HowToPlayProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm p-4"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="bg-white rounded-xl shadow-xl max-w-lg w-full max-h-[90vh] overflow-y-auto"
          >
            <div className="p-6 relative">
              <button
                onClick={onClose}
                className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors"
              >
                <X className="h-6 w-6" />
              </button>

              <h2 className="text-2xl font-bold text-gray-900 mb-6">How to Play Alumni Game</h2>

              <div className="space-y-6 text-gray-600">
                <p className="text-lg">
                  Test your NBA knowledge! Can you remember where the pros played college ball?
                </p>

                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">Game Rules</h3>
                  <ol className="list-decimal list-inside space-y-2">
                    <li>You'll see 10 NBA players in their pro uniforms</li>
                    <li>Type in which college they attended</li>
                    <li>After you guess, see them in their college jersey with stats</li>
                    <li>Score points for correct answers and build streaks!</li>
                  </ol>
                </div>

                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">Scoring</h3>
                  <ul className="space-y-2">
                    <li>100 points for each correct answer</li>
                    <li className="font-medium">Streak bonuses:</li>
                    <ul className="ml-4 space-y-1">
                      <li>3 correct: 2x multiplier</li>
                      <li>5 correct: 3x multiplier</li>
                      <li>7 correct: 4x multiplier</li>
                    </ul>
                    <li>Perfect game bonus: 1000 points</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">Tips</h3>
                  <ul className="space-y-2">
                    <li>Common abbreviations work (UCLA, UNC, etc.)</li>
                    <li>Watch your streak counter for multipliers</li>
                    <li>Take your time - no penalty for thinking!</li>
                    <li>After 10 players, share your score and challenge friends</li>
                  </ul>
                </div>

                <p className="text-center font-medium">
                  Ready to test your college hoops knowledge? Let's play! 🏀
                </p>
              </div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={onClose}
                className="w-full mt-6 py-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors"
              >
                Got it!
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}