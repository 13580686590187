import { useState, useCallback, useEffect } from 'react';

export function useAutocomplete(items: string[], onSubmit: (value: string) => void) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);

  const getSuggestions = useCallback(
    (value: string) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      return inputLength < 2
        ? []
        : items
            .filter(item => item.toLowerCase().includes(inputValue))
            .slice(0, 8);
    },
    [items]
  );

  useEffect(() => {
    const newSuggestions = getSuggestions(inputValue);
    setSuggestions(newSuggestions);
    setIsOpen(newSuggestions.length > 0);
    setSelectedIndex(-1);
  }, [inputValue, getSuggestions]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!suggestions.length) return;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setSelectedIndex(prev => 
          prev < suggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        event.preventDefault();
        setSelectedIndex(prev => (prev > 0 ? prev - 1 : prev));
        break;
      case 'Enter':
        event.preventDefault();
        if (selectedIndex >= 0) {
          handleSuggestionClick(suggestions[selectedIndex]);
        } else if (inputValue.trim()) {
          onSubmit(inputValue);
          setIsOpen(false);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        break;
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setInputValue(suggestion);
    setIsOpen(false);
    onSubmit(suggestion);
  };

  const clearInput = () => {
    setInputValue('');
    setIsOpen(false);
    setSelectedIndex(-1);
  };

  return {
    inputValue,
    suggestions,
    selectedIndex,
    isOpen,
    handleInputChange,
    handleKeyDown,
    handleSuggestionClick,
    clearInput,
  };
}