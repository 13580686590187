import { useState, useCallback, useEffect } from 'react';

interface DropdownStyle {
  top: number;
  left: number;
  width: number;
  '--dropdown-width': string;
}

export function useDropdownPosition(
  containerRef: React.RefObject<HTMLElement>,
  dropdownRef: React.RefObject<HTMLElement>
) {
  const [dropdownStyle, setDropdownStyle] = useState<DropdownStyle>({
    top: 0,
    left: 0,
    width: 0,
    '--dropdown-width': '0px',
  });

  const updatePosition = useCallback(() => {
    if (!containerRef.current) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const dropdownHeight = 200; // Max height of dropdown

    // Calculate if there's room below
    const spaceBelow = viewportHeight - containerRect.bottom;
    const spaceAbove = containerRect.top;
    const showBelow = spaceBelow >= dropdownHeight || spaceBelow > spaceAbove;

    const top = showBelow
      ? containerRect.bottom + 4 // 4px gap
      : containerRect.top - dropdownHeight - 4;

    setDropdownStyle({
      top,
      left: containerRect.left,
      width: containerRect.width,
      '--dropdown-width': `${containerRect.width}px`,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', updatePosition, true);
    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('scroll', updatePosition, true);
      window.removeEventListener('resize', updatePosition);
    };
  }, [updatePosition]);

  return { dropdownStyle, updatePosition };
}