import React from 'react';
import { motion } from 'framer-motion';
import { HelpCircle } from 'lucide-react';
import { HowToPlay } from './HowToPlay';
import '../styles/animations.css';
import { GAME_LENGTH } from '../data/players';

interface BannerProps {
  onBack?: () => void;
  currentRound: number;
  score: number;
  streak: number;
}

export function Banner({ onBack, currentRound, score, streak }: BannerProps) {
  const [showHowToPlay, setShowHowToPlay] = React.useState(false);
  const scoreRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (streak >= 3 && scoreRef.current) {
      scoreRef.current.classList.add('streak-milestone');
      const cleanup = () => scoreRef.current?.classList.remove('streak-milestone');
      scoreRef.current.addEventListener('animationend', cleanup);
      return () => scoreRef.current?.removeEventListener('animationend', cleanup);
    }
  }, [streak]);

  return (
    <div className="flex flex-col items-center px-4 pt-6 pb-4 md:pt-8 md:pb-6">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-6 w-full"
      >
        <div className="flex flex-col items-center gap-1">
          <div className="flex items-center justify-center gap-2 mb-1">
            <span className="text-xl md:text-2xl">🏀</span>
            <h1 className="text-2xl md:text-3xl font-cal font-bold text-gray-900">
              Big Brain Sports
            </h1>
            <span className="text-xl md:text-2xl">🎓</span>
          </div>
          <h2 className="text-xl md:text-2xl font-cal font-bold bg-gradient-to-r from-orange-500 to-orange-600 bg-clip-text text-transparent mb-2">
            Alumni Game
          </h2>
          <div className="flex items-center justify-center gap-2 whitespace-nowrap">
            <p className="text-sm md:text-base text-gray-600 font-space">
              Match NBA stars to their college teams
            </p>
            <button
              onClick={() => setShowHowToPlay(true)}
              className="p-1 text-orange-500 hover:text-orange-600 transition-colors"
              aria-label="How to play"
            >
              <HelpCircle className="h-4 w-4 md:h-5 md:w-5" />
            </button>
          </div>
        </div>
      </motion.div>

      <div className="w-full max-w-sm grid grid-cols-3 gap-6">
        <div className="flex flex-col items-center">
          <p className="text-xs text-gray-500 uppercase tracking-wider font-space mb-1 whitespace-nowrap">Round</p>
          <p className="text-lg md:text-xl font-bold text-orange-600">{currentRound + 1}/{GAME_LENGTH}</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-xs text-gray-500 uppercase tracking-wider font-space mb-1 whitespace-nowrap">Score</p>
          <p className="text-lg md:text-xl font-bold text-orange-600">{score}</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-xs text-gray-500 uppercase tracking-wider font-space mb-1 whitespace-nowrap">Streak</p>
          <p className="text-lg md:text-xl font-bold text-orange-600">{streak}</p>
        </div>
      </div>
      
      <HowToPlay isOpen={showHowToPlay} onClose={() => setShowHowToPlay(false)} />
    </div>
  );
}