import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, X } from 'lucide-react';
import { useAutocomplete } from '../hooks/useAutocomplete';
import { useDropdownPosition } from '../hooks/useDropdownPosition';

interface AutocompleteInputProps {
  onSubmit: (value: string) => void;
  disabled?: boolean;
  schools: string[];
}

export function AutocompleteInput({ onSubmit, disabled, schools }: AutocompleteInputProps) {
  const {
    inputValue,
    suggestions,
    selectedIndex,
    isOpen,
    handleInputChange,
    handleKeyDown,
    handleSuggestionClick,
    clearInput,
  } = useAutocomplete(schools, onSubmit);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const { dropdownStyle, updatePosition } = useDropdownPosition(containerRef, dropdownRef);

  React.useEffect(() => {
    if (isOpen) {
      updatePosition();
    }
  }, [isOpen, suggestions.length, updatePosition]);

  return (
    <div ref={containerRef} className="relative flex flex-col gap-2">
      <div className="relative">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter college name..."
          disabled={disabled}
          autoComplete="off"
          spellCheck="false"
          className="w-full px-4 py-2.5 pl-9 text-base rounded-lg border border-gray-100 focus:border-gray-200 outline-none transition-colors placeholder:text-gray-400 bg-white/75 focus:bg-white"
        />
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400/75" />
        {inputValue && (
          <button
            onClick={clearInput}
            className="absolute right-2 top-1/2 -translate-y-1/2 p-1 text-gray-400/75 hover:text-gray-600 transition-colors"
          >
            <X className="h-4 w-4 text-gray-400" />
          </button>
        )}
      </div>

      <AnimatePresence>
        {isOpen && suggestions.length > 0 && (
          <motion.div
            ref={dropdownRef}
            initial={{ opacity: 0, y: -4 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -4 }}
            className="w-full bg-gray-50/90 backdrop-blur-sm rounded-lg border border-gray-100/50 max-h-[160px] overflow-y-auto overscroll-contain"
          >
            {suggestions.map((suggestion, index) => (
              <button
                key={suggestion}
                onClick={() => handleSuggestionClick(suggestion)}
                className={`w-full px-3 py-1.5 text-left text-sm hover:bg-white/75 transition-colors ${
                  index === selectedIndex ? 'bg-white text-blue-600' : ''
                }`}
              >
                {suggestion}
              </button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}